import { useEffect, useState } from "react";
import "./app.css";
import "./custom.css";

export default () => {
    const data = [
        {
            link: "https://order.tabin.co.nz/restaurant/d37c0885-73f2-43b3-88e5-48b5caedd072",
            name: "Manukau",
            address: "203 Cavendish Drive, Papatoetoe, Auckland 2104",
            phone: "09 250 1926",
            available: true,
        },
        {
            link: "https://food-hub.nz/Panmure/Beach-Pizza-Panmure/ordernow#sides",
            name: "Panmure",
            address: "13/71 Jellicoe Road, Panmure, Auckland 1072",
            phone: "09 600 1050",
            available: true,
        },
        {
            link: "https://beachpizzamanurewa.co.nz/order-now",
            name: "Manurewa",
            address: "41 Station Road, Manurewa, Auckland 2102",
            phone: "09 267 9817",
            available: true,
        },
        {
            link: "https://pizzasandmocktails.co.nz",
            name: "Avondale",
            address: "2020 Great North Road, Avondale, Auckland 1026",
            phone: "09 212 2123",
            available: true,
        },
        // {
        //     link: "https://www.beachpizzaorewa.co.nz/",
        //     name: "Orewa",
        //     address: "8P Moana Avenue, Orewa, Auckland 0931",
        //     phone: "09 426 4264",
        //     available: true,
        // },
        {
            link: "https://beachpizzapapatoetoe.co.nz/order-now",
            name: "Papatoetoe",
            address: "66 Portage Road, Papatoetoe, Auckland 2025",
            phone: "09 216 1211",
            available: true,
        },
        {
            link: "https://www.beachpizzabeachlands.co.nz/",
            name: "Beachlands",
            address: "6/42 Third View Avenue, Beachlands 2018",
            phone: "09 536 5151",
            available: true,
        },
        {
            link: "https://beachpizzaglendene.co.nz/order-now",
            name: "Glendene",
            address: "238A Great North Road, Glendene, Auckland 0612",
            phone: "09 837 3786",
            available: true,
        },
        {
            link: "http://beachpizzatakanini.co.nz/",
            name: "Takanini",
            address: "Shop 3/86 Kauri Heart Avenue, Takanini, Auckland 2112",
            phone: "09 296 1296",
            available: true,
        },
        {
            link: "https://beachpizzapapamoa.co.nz/order-now/",
            name: "Papamoa",
            address: "Unit 11/32 Turiwhatu Street, Papamoa Beach, Papamoa 3118",
            phone: "07 542 0666",
            available: true,
        },
        {
            link: "https://beachpizzamelville.co.nz/",
            name: "Melville",
            address: "5 Normandy Avenue, Melville, Hamilton 3206",
            phone: "07 390 1009",
            available: true,
        },
        {
            link: "https://beachpizzapukekohe.co.nz/",
            name: "Pukekohe",
            address: "12 Hall Street, Pukekohe 2120",
            phone: "09 947 7326",
            available: true,
        },
        {
            link: "https://beachpizzaonehunga.co.nz/",
            name: "Onehunga",
            address: "107 Trafalgar Street, Onehunga, Onehunga, 1061",
            phone: "09 212 4665",
            available: true,
        },
    ];

    const [searchTerm, setSearchTerm] = useState("");
    const [restaurantData, setRestaurantData] = useState(data);

    useEffect(() => {
        const newData = [];

        if (searchTerm) {
            data.forEach((restaurant) => {
                if (restaurant.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    newData.push(restaurant);
                }
            });

            setRestaurantData(newData);
        } else {
            setRestaurantData(data);
        }
    }, [searchTerm]);

    const onOrder = (link) => {
        window.location.href = link;
    };

    return (
        <>
            <img src={`https://tabin-public.s3.ap-southeast-2.amazonaws.com/images/beach+pizza+logo+2.png`} className="logo" alt="logo" />
            <img src={`https://tabin-public.s3.ap-southeast-2.amazonaws.com/images/Beach+Pizza_header.jpg`} className="banner" alt="logo" />
            <div className="restaurant-list">
                <div className="h4 text-center mb-6">
                    Welcome to Beach Pizza. We make a wide range of pizzas and sides. We do our own special marination for meat and create our own
                    sauces. Feel free to come in-store and order or call and place order ready for pick up. Click Order Here here to start!
                </div>

                <div className="search-bar-heading mb-2">Store Name</div>
                <input
                    className="input mb-4"
                    type="text"
                    name="search"
                    value={searchTerm}
                    placeholder="Search..."
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                />
                {restaurantData.map((restaurant, index) => (
                    <div key={restaurant.id}>
                        {index != 0 && <div className="separator-5"></div>}
                        <div className="restaurant-list-item">
                            <div>
                                <div className="restaurant-name">{restaurant.name}</div>
                                <div className="mt-1">{restaurant.address}</div>
                                <div className="mt-1">{restaurant.phone}</div>
                            </div>
                            <button
                                className={`button ${restaurant.available ? "" : "disabled"}`}
                                onClick={() => {
                                    onOrder(restaurant.link);
                                }}
                            >
                                {restaurant.available ? "Order Here" : "Unavailable"}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
